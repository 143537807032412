:root {
  --primary: #ec6565;

  --white: #ffffff;
  --black: #000000;
  --grey: #757575;
  --transparent: rgba(255, 255, 255, 0);

  --success: #27e79e;
  --error: #ef676f;
  --link: #66b3ff;
  --warn: #ffcc66;

  --bg-1: #110808;
  --bg-2: #231010;

  --text-1: #dcdcdd;
  --text-2: #aeaeb0;
  --text-3: #808083;
  --text-4: #69686c;

  --dark-text-1: rgba(255, 255, 255, 0.85);
  --dark-text-2: rgba(255, 255, 255, 0.65);
  --dark-text-3: rgba(255, 255, 255, 0.45);
  --dark-text-35: rgba(255, 255, 255, 0.35);
  --link-secondary: #66b3ff;

  --border-1: #1e1515;
  --border-5: #302f34;
  --border-6: #29282e;

  --opacity-85: rgba(0, 0, 0, 0.85);
  --opacity-8: rgba(0, 0, 0, 0.8);
  --opacity-7: rgba(0, 0, 0, 0.7);
  --opacity-5: rgba(0, 0, 0, 0.5);
  --opacity-3: rgba(0, 0, 0, 0.3);
  --opacity-1: rgba(0, 0, 0, 0.1);
  --primary-opacity-8: rgba(236, 101, 101, 0.8);
  --bg-loader: #110808;

  --white-opacity-1: rgba(255, 255, 255, 0.1);
  --white-opacity-07: rgba(255, 255, 255, 0.07);
  --white-opacity-05: rgba(255, 255, 255, 0.05);
}
