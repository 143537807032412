@import "./mixins";

.enjaname-home-page {
  position: relative;
  .header-section {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    z-index: 100;
    background: transparent;
    transition: all 0.3s;
    &.header-active {
      background: var(--opacity-3);
      -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
    }
  }

  .enjaname-cover-section {
    position: relative;
    width: 100%;
    height: 560px;
    background: url(../../img/cover-img.png) center center no-repeat;
    background-size: cover;
    @include for-tab-below() {
      height: 360px;
    }
    &::after {
      content: "Enjaname";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 14vw;
      color: var(--primary-opacity-8);
      font-weight: 700;
    }
    &.about-section {
      height: 100vh;
      &::after {
        content: "" !important;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: url(../../img/enjaname-about.svg) center center no-repeat;
        background-size: contain;
        max-width: 400px;
        width: 100%;
        @include for-tab-below() {
          max-width: 200px;
        }
      }
    }
  }

  .enjaname-topic-section {
    height: 100px;
    width: 100%;
    border-bottom: 1px solid var(--border-1);
    .topic-title {
      letter-spacing: 3px;
      color: var(--primary);
    }
  }
  .enjaname-podcast-details {
    max-width: 700px;
    margin: 0 auto;
    .logo-img {
      width: 100px;
      height: 100px;
    }
  }
  .enjaname-podcast-episodes {
    max-width: 700px;
    margin: 0 auto;
    .enjaname-episode {
    }
  }
}

.enjaname-loader {
  height: 100vh;
  background: var(--bg-loader);
  .loader-text {
    letter-spacing: 12px;
    color: var(--primary-opacity-8) !important;
  }
}

.text2-reveal {
  transform: translateY(0);
  animation: textAnimation 1.5s ease-in-out 1;
  opacity: 1;
}

@keyframes textAnimation {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
}

// .footer-section {
//   position: absolute;
//   width: 100%;
//   bottom: 0;
//   z-index: 100;
// }
